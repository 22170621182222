<style scoped>
.listRecettes {
  height: 550px;
  overflow: scroll;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxIngredient {
  border: 1px solid #dfe0e1;
  padding: 10px;
  margin-bottom: 5px;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Container, Draggable } from "vue-dndrop";
import AsyncError from "@/components/ingredient/AsyncError.vue";
import AsyncLoading from "@/components/ingredient/AsyncLoading.vue";
const AsyncIngredient = import("@/components/ingredient/AsyncIngredient.vue");

/**
 * Profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Container,
    Draggable,
    AsyncComponent: () => ({
      component: AsyncIngredient,
      loading: AsyncLoading,
      error: AsyncError,
      delay: 0,
      timeout: 3000,
    }),
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Recettes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    this.getAllRecettes();
    this.getAllFoods();
  },
  data() {
    return {
      title: "Recettes",
      saving_modification: false,
      saving_photo: false,
      adding_recette: false,
      deleting_recette: false,
      transloading_photo: false,
      add_recette: false,
      ingredients: [],
      forceUpdateList: 1,
      recettes: [],
      recette: null,
      uploadValue: 0,
      pourcProteine: 0,
      pourcGlucide: 0,
      pourcLipide: 0,
      macros: ["proteine", "glucide", "lipide"],
      vitamines: [
        "a",
        "c",
        "d",
        "e",
        "k",
        "b1",
        "b2",
        "b3",
        "b5",
        "b6",
        "b9",
        "b12",
      ],
      mineraux: [
        "calcium",
        "phosphore",
        "magnesium",
        "potassium",
        "sodium",
        "fer",
        "cuivre",
        "zinc",
        "mangenese",
        "selenium",
        "iode",
      ],
      categories: [
        "vegetable",
        "Fruit",
        "Starchy food",
        "Dairy",
        "Protein",
        "Fat",
        "Supplement",
        "Drinks",
      ],
      repas: ["breakfast", "snack", "meal"],
      regimes: ["vegan", "vegetarien", "sansgluten", "sanslactose"],
      types: ["sucre", "sel"],
      langues: ["it", "fr", "us", "es", "br"],
      items: [
        {
          text: "Recettes",
        },
        {
          text: "recettes",
          active: true,
        },
      ],
    };
  },
  methods: {
    setTimestampDate() {
      var date = document.getElementById("date").value;
      var timestamp = new Date(date).getTime();
      this.recette.data.date = timestamp;
    },
    timestampToDate(timestamp) {
      if(timestamp != null)
      return new Date(timestamp).toISOString().substr(0, 10);
      else
      return new Date().toISOString().substr(0, 10);
    },
    deleteIngredient(key) {
      this.recette.data.ingredients.splice(key, 1);
      this.forceUpdateList++;
    },
    updateMacrosCalcul() {
      var totalProteine = 0;
      var totalGlucide = 0;
      var totalLipide = 0;

      this.pourcProteine = 0;
      this.pourcGlucide = 0;
      this.pourcLipide = 0;

      var allIngredientsList = document.querySelectorAll(
        '[id^="boxIngredientRecette"]'
      );
      allIngredientsList.forEach((ingredient) => {
        if (ingredient.querySelector('[id^="macrosIngredients"]') != null) {
          var horscalcul = ingredient.querySelector(
            '[id^="horscalculingredient"]'
          ).checked;
          var quantite = parseFloat(
            ingredient.querySelector('[id^="quantiteingredient"]').value
          );

          console.log(
            parseFloat(
              ingredient.querySelector('[id^="macrosIngredients"]').dataset
                .proteine + "g proteine"
            ) +
              "g proteine" +
              quantite +
              "quantite" +
              " hors calcul:" +
              horscalcul
          );

          if (horscalcul) {
            totalProteine +=
              parseFloat(
                ingredient.querySelector('[id^="macrosIngredients"]').dataset
                  .proteine
              ) * quantite;
            totalGlucide +=
              parseFloat(
                ingredient.querySelector('[id^="macrosIngredients"]').dataset
                  .glucide
              ) * quantite;
            totalLipide +=
              parseFloat(
                ingredient.querySelector('[id^="macrosIngredients"]').dataset
                  .lipide
              ) * quantite;
          } else {
            totalProteine += parseFloat(
              (ingredient.querySelector('[id^="macrosIngredients"]').dataset
                .proteine *
                quantite) /
                100
            );
            totalGlucide += parseFloat(
              (ingredient.querySelector('[id^="macrosIngredients"]').dataset
                .glucide *
                quantite) /
                100
            );
            totalLipide += parseFloat(
              (ingredient.querySelector('[id^="macrosIngredients"]').dataset
                .lipide *
                quantite) /
                100
            );
          }
          console.log("suivi prot" + totalProteine);
        }
      });

      console.log("totalproteine: " + totalProteine);

      var totalKcal = totalProteine * 4 + totalGlucide * 4 + totalLipide * 9;

      this.pourcProteine = Math.round((totalProteine * 4 * 100) / totalKcal);
      this.pourcGlucide = Math.round((totalGlucide * 4 * 100) / totalKcal);
      this.pourcLipide = Math.round((totalLipide * 9 * 100) / totalKcal);
    },
    onDrop(dropResult) {
      //console.log('collection:' + JSON.stringify(dropResult));

      //L'index de l'ingredient (dans le tableau ingredient) qui a été draggé
      var indexIngredient = dropResult.payload;
      var ingredientSelect = this.ingredients[indexIngredient];

      console.log(
        "ingredient selectionne: " + JSON.stringify(ingredientSelect)
      );

      //Dans quel index du tableau des ingredients de la recette on drop l'élément draggé
      var indexDansRecette = dropResult.addedIndex;

      //On construit le JSON du nouvel ingredient
      var newIngredient = {
        hors_calcul: false,
        ingredient: firebase
          .firestore()
          .doc("ingredients/" + ingredientSelect.id),
        quantite: 0,
      };

      console.log(newIngredient);

      //On transforme maintenant le JSON d'ingrédient dans la recette en array
      var tabIngredientsRecette = this.recette.data.ingredients;
      tabIngredientsRecette.splice(indexDansRecette, 0, newIngredient);

      //On réinsère les ingredients dans la recette
      this.recette.data.ingredients = tabIngredientsRecette;

      this.forceUpdateList++;
    },
    getChildPayload1(id) {
      return id;
    },
    getGhostParent() {
      return document.body;
    },
    onDropReady(dropResult) {
      console.log("drop ready", dropResult);
    },
    async transloadPhoto() {
      this.transloading_photo = true;

      var url = this.recette.data.photo.original;

      var data = {
        id: this.recette.id,
        url: url,
        type: "recettes",
      };

      var transloadPhoto = firebase.functions().httpsCallable("transloadPhoto");
      transloadPhoto(data).then((result) => {
        // Read result of the Cloud Function.
        console.log(result);
        this.recette.data.photo = result.data.photo;
        firebase
          .firestore()
          .collection("recettes")
          .doc(data.id)
          .update(this.recette.data);
        this.transloading_photo = false;
      });
    },
    async transferPhoto() {
      this.saving_photo = true;

      var newPhoto = document.getElementById("newPhoto").files[0];
      console.log(newPhoto);
      var type = newPhoto.name.split(".").pop();
      var filename =
        "recettes/" + this.recette.id + "-" + Date.now() + "." + type;

      const storageRef = firebase
        .app()
        .storage("gs://gabriellavicofitness")
        .ref(filename)
        .put(newPhoto);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            var db = firebase.firestore();
            db.collection("recettes")
              .doc(this.recette.id)
              .update({
                photo: {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                },
              })
              .then(() => {
                console.log("ingredient mis a jour");
                this.recette.data.photo = {
                  original: url,
                  optimized: null,
                  thumbnail: null,
                };
                this.saving_photo = false;
                this.uploadValue = 0;
                document.getElementById("newPhoto").value = "";
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          });
        }
      );
    },
    saveModificationsRecette() {
      this.saving_modification = true;

      var db = firebase.firestore();
      db.collection("recettes")
        .doc(this.recette.id)
        .update(this.recette.data)
        .then(() => {
          console.log("Document successfully written!");
          this.saving_modification = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    addNewRecette() {
      this.adding_recette = true;
      console.log("id recette:" + this.recette.id);
      console.log("recette:" + this.recette.data);
      var db = firebase.firestore();
      db.collection("recettes")
        .doc(this.recette.id)
        .set(this.recette.data)
        .then(() => {
          this.adding_ingredient = false;
          this.add_ingredient = false;
          this.recettes = [];
          this.recette = null;
          this.getAllRecettes();
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPhotoRecette(recette) {
      if (recette.data.photo.original != null)
        return recette.data.photo.original;
      else return require("@/assets/images/nophoto.png");
    },
    getPhotoRecetteThumb(recette) {
      if (recette.data.photo.thumbnail != null)
        return recette.data.photo.thumbnail;
      else return require("@/assets/images/nophoto.png");
    },
    searchIngredient() {
      var search = document.getElementById("searchfood").value.toLowerCase();
      var category = document.getElementById("searchCategory").value;
      //filtrer en javascript

      document
        .querySelectorAll("[data-type='ingredient']")
        .forEach((element) => {
          var id_ingredient = element.dataset.id;
          //On récupère le JSON de l'ingredient avec son id
          let ingredient = this.ingredients.find(
            (el) => el.id === id_ingredient
          );

          //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
          if (
            (ingredient.data.description.it.nom
              .toLowerCase()
              .includes(search) ||
              ingredient.data.description.fr.nom
                .toLowerCase()
                .includes(search) ||
              ingredient.data.description.us.nom
                .toLowerCase()
                .includes(search) ||
              search == "") &&
            (ingredient.data.category == category || category == "all")
          ) {
            console.log("block");
            element.style = "display: block";
          } else {
            console.log("none");
            element.style = "display: none";
          }
        });
    },
    searchRecette() {
      var search = document.getElementById("searchrecette").value.toLowerCase();
      var repas = document.getElementById("searchRepas").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='recette']").forEach((element) => {
        var id_recette = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let recette = this.recettes.find((el) => el.id === id_recette);

        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (recette.data.description.it.nom.toLowerCase().includes(search) ||
            recette.data.description.fr.nom.toLowerCase().includes(search) ||
            recette.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (recette.data.repas.includes(repas) || repas == "all")
        ) {
          console.log("block");
          element.style = "display: block";
        } else {
          console.log("none");
          element.style = "display: none";
        }
      });
    },
    getAllFoods() {
      var db = firebase.firestore();
      db.collection("ingredients")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.ingredients.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    getAllRecettes() {
      var db = firebase.firestore();
      db.collection("recettes")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.recettes.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    showIngredientFromRef(refIngredient) {
      refIngredient.get().then((data) => {
        data;
      });
    },
    async setRecette(recette) {
      this.add_recette = false;
      this.recette = recette;
    },
    showAddRecette() {
      var json_recette = {
        photo: {
          original: null,
          optimized: null,
          thumbnail: null,
        },
        type: "",
        temps_preparation: 0,
        temps_cuisson: 0,
        status: {
          it: false,
          fr: false,
          us: false,
          es: false,
          br: false,
        },
        repas: [],
        regime: [],
        description: {
          it: {
            nom: "",
            recette: "",
          },
          fr: {
            nom: "",
            recette: "",
          },
          us: {
            nom: "",
            recette: "",
          },
          es: {
            nom: "",
            recette: "",
          },
          br: {
            nom: "",
            recette: "",
          },
        },
        ingredients: [],
      };

      this.recette = {
        type: "new",
        id: String(Date.now()),
        data: json_recette,
      };

      this.add_recette = true;
    },
    getFlag(key) {
      return (
        "<img src='https://flagcdn.com/h20/" + key + ".png' height='12' />"
      );
    },
    hideModal() {
      this.$refs["delete-modal"].hide();
    },
    showModal() {
      this.$refs["delete-modal"].show();
    },
    deleteRecette() {
      this.deleting_recette = true;
      var db = firebase.firestore();
      db.collection("recettes")
        .doc(this.recette.id)
        .delete()
        .then(() => {
          this.$refs["delete-modal"].hide();
          this.deleting_recette = false;
          this.add_ingredient = false;
          this.recettes = [];
          this.recette = null;
          this.getAllRecettes();
          console.log("Document successfully deleted!");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal ref="delete-modal" hide-footer title="Delete recette">
      <div class="d-block text-center">
        <h3>Are you sure ?!</h3>
        <p>This action is irreversible</p>
      </div>
      <div class="d-grid gap-2">
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="deleteRecette"
        >
          <span
            v-if="deleting_recette"
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Yes, delete definitely</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-warning"
          block
          @click="hideModal"
          >No, close</b-button
        >
      </div>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-3 pt-2 pb-0 card" style="min-height: 650px">
          <div class="mb-2">
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                type="button"
                @click="showAddRecette()"
              >
                + Add Recette
              </button>
            </div>
            <hr />
          </div>
          <div class="mb-0">
            <input
              type="text"
              class="form-control"
              id="searchrecette"
              placeholder="Search ingredient..."
              @keyup="searchRecette()"
            />
            <select
              class="form-select mt-2"
              aria-label="Default select example"
              @change="searchRecette()"
              id="searchRepas"
            >
              <option value="all" selected>Tous les repas</option>
              <option
                v-for="repa in repas"
                :key="'categorysearch' + repa"
                :value="repa"
              >
                {{ repa }}
              </option>
            </select>
            <div class="alert alert-secondary mt-2 px-2 py-1" role="alert">
              <small>{{ recettes.length }} recettes</small>
            </div>
            <div class="listRecettes">
              <div
                v-for="(recette, key) in recettes"
                :key="'recette' + key"
                :id="'recetteid' + key"
                :data-id="recette.id"
                data-type="recette"
                @click="setRecette(recette)"
              >
                <div class="container boxlist px-0 py-2">
                  <div class="row">
                    <div class="col-auto">
                      <img
                        width="50px"
                        :src="getPhotoRecetteThumb(recette)"
                        :key="'photothumb' + recette.id"
                        alt
                        class="img-thumbnail"
                      />
                    </div>
                    <div class="col">
                      <p class="fw-bold mb-0">
                        {{ recette.data.description.it.nom }}
                      </p>
                      <p class="fw-light smalltext mb-0">
                        {{ recette.data.repas }}
                      </p>
                      <div>
                        <div
                          v-for="(value, name, key) in recette.data.description"
                          :key="'infodescription' + key"
                          class="d-inline p-1"
                        >
                          <img
                            :src="'https://flagcdn.com/h20/' + name + '.png'"
                            height="12"
                            :style="
                              recette.data.description[name].nom == '' ||
                              recette.data.description[name].nom == null
                                ? 'opacity: 0.3'
                                : 'opacity: 1'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card cardDetailFood">
            <div v-if="recette == null" class="text-center">Select recette</div>
            <div v-if="recette != null" class="p-3">
              <div class="container px-0 py-2">
                <div class="row">
                  <div class="col-2">
                    <img
                      width="100%"
                      :src="getPhotoRecette(recette)"
                      :key="'photo' + recette.id"
                      alt
                      class="img-thumbnail"
                    />
                    <ul>
                      <li>
                        <small
                          >Original:
                          <a
                            :href="recette.data.photo.original"
                            target="_blank"
                            >{{ recette.data.photo.original }}</a
                          ></small
                        >
                      </li>
                      <li>
                        <small
                          >Optimized:
                          <a
                            :href="recette.data.photo.optimized"
                            target="_blank"
                            >{{ recette.data.photo.optimized }}</a
                          ></small
                        >
                      </li>
                      <li>
                        <small
                          >Thumbnail:
                          <a
                            :href="recette.data.photo.thumbnail"
                            target="_blank"
                            >{{ recette.data.photo.thumbnail }}</a
                          ></small
                        >
                      </li>
                    </ul>
                    <div class="d-grid gap-2 mt-2" v-if="recette.type != 'new'">
                      <label for="formFile" class="form-label"
                        >Change photo:</label
                      >
                      <input class="form-control" type="file" id="newPhoto" />
                      <progress
                        v-if="saving_photo"
                        id="progress"
                        :value="uploadValue"
                        max="100"
                      ></progress>
                      <button
                        class="btn btn-primary btn-sm"
                        type="button"
                        id="uploadPhoto"
                        @click="transferPhoto()"
                      >
                        <span
                          v-if="saving_photo"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update photo
                      </button>
                      <div class="alert alert-secondary" role="alert">
                        <i class="fas fa-exclamation-circle"></i> Dimension
                        1000x400
                      </div>
                      <div
                        v-if="recette.data.photo.original != null"
                        class="d-grid gap-2 mt-2"
                      >
                        <button
                          class="btn btn-info btn-sm"
                          type="button"
                          id="uploadPhoto"
                          @click="transloadPhoto()"
                        >
                          <span
                            v-if="transloading_photo"
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Transload photo
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-10">
                    <div class="container">
                      <div class="row">
                        <div class="col-8">
                          <h1 class="mb-0">
                            {{ recette.data.description.it.nom }}
                          </h1>
                          <small>Recette #{{ recette.id }}</small>
                        </div>
                        <div class="col-4">
                          <div class="d-grid gap-2">
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="saveModificationsRecette()"
                              v-if="recette.type != 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Save modifications
                            </button>
                            <b-button
                              id="show-btn"
                              class="float-end"
                              variant="danger"
                              @click="showModal"
                              v-if="recette.type != 'new'"
                              >Delete</b-button
                            >
                            <button
                              type="button"
                              class="btn btn-success btn-lg float-end"
                              @click="addNewRecette()"
                              v-if="recette.type == 'new'"
                            >
                              <span
                                v-if="saving_modification"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Add Recette
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="alert alert-secondary" role="alert">
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <b-form-group label="Repas:">
                              <b-form-checkbox-group
                                id="repas_recette"
                                v-model="recette.data.repas"
                                :options="repas"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>
                          <div class="col">
                            <b-form-group label="Regimes:">
                              <b-form-checkbox-group
                                id="regime_recette"
                                v-model="recette.data.regime"
                                :options="regimes"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </div>

                          <div class="col">
                            <div class="row">
                              <label>Type:</label>
                              <b-form-select
                                v-model="recette.data.type"
                                :options="types"
                                id="type_recette"
                                class="mb-3"
                                value-field="type"
                                text-field="type"
                                disabled-field="notEnabled"
                              ></b-form-select>
                            </div>
                            <div class="row">
                              <label>Temps prépa (min):</label>
                              <b-form-input
                                v-model="recette.data.temps_preparation"
                                id="temps_preparation"
                                placeholder="Temps préparation en minutes"
                              ></b-form-input>
                            </div>
                            <div class="row">
                              <label>Temps cuisson (min):</label>
                              <b-form-input
                                v-model="recette.data.temps_cuisson"
                                id="temps_cuisson"
                                placeholder="Temps cuisson en minutes"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label for="example-input"
                        >Date de publication (Y-m-d)</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        id="date"
                        :value="timestampToDate(recette.data.date)"
                        @change="setTimestampDate()"
                      />
                    </div>

                    <div class="container px-0 py-2">
                      <div class="row">
                        <b-tabs content-class="mt-3">
                          <b-tab
                            v-for="(langue, key) in recette.data.description"
                            :key="'descriptiontab-' + key"
                            :active="key === 'it'"
                          >
                            <template slot="title">
                              <img
                                :src="'https://flagcdn.com/h20/' + key + '.png'"
                                height="12"
                              />
                              {{ key }}
                            </template>

                            <div class="mb-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                :id="'status_' + key"
                                v-model="recette.data.status[key]"
                              />

                              <label :for="'nom_' + key" class="form-label"
                                >Publish ?</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                :id="'nom_' + key"
                                v-model="recette.data.description[key].nom"
                              />
                            </div>
                            <div class="mb-3">
                              <label :for="'recette_' + key" class="form-label"
                                >Recette*</label
                              >
                              <textarea
                                class="form-control"
                                :id="'recette_' + key"
                                rows="3"
                                v-model="recette.data.description[key].recette"
                              ></textarea>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                    <hr />
                    <div class="container px-0 py-2">
                      <div class="alert alert-info text-center" role="alert">
                        <i class="fas fa-magic"></i> Creator Tool
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <input
                            type="text"
                            class="form-control"
                            id="searchfood"
                            placeholder="Search ingredient..."
                            @keyup="searchIngredient()"
                          />
                          <select
                            class="form-select mt-2"
                            aria-label="Default select example"
                            @change="searchIngredient()"
                            id="searchCategory"
                          >
                            <option value="all" selected>All categories</option>
                            <option
                              v-for="category in categories"
                              :key="'categorysearch' + category"
                              :value="category"
                            >
                              {{ category }}
                            </option>
                          </select>
                          <div class="listRecettes">
                            <Container
                              class="item"
                              style="min-height: 50px;"
                              behaviour="copy"
                              group-name="1"
                              :get-child-payload="getChildPayload1"
                            >
                              <Draggable
                                v-for="ingredient in ingredients"
                                :key="ingredient.id"
                              >
                                <div
                                  class="draggable-item"
                                  data-type="ingredient"
                                  :data-id="ingredient.id"
                                >
                                  <div class="container boxlist px-0 py-2">
                                    <div class="row">
                                      <div class="col">
                                        <p class="fw-bold mb-0">
                                          {{
                                            ingredient.data.description.it.nom
                                          }}
                                        </p>
                                        <p class="fw-light smalltext mb-0">
                                          Prot.: {{ ingredient.data.proteine }}g
                                          | Gluc.:
                                          {{ ingredient.data.glucide }}g | Lip:
                                          {{ ingredient.data.lipide }}g
                                        </p>
                                        <div>
                                          <div
                                            v-for="(
                                              value, name, key
                                            ) in ingredient.data.description"
                                            :key="'infodescription' + key"
                                            class="d-inline p-1"
                                          >
                                            <img
                                              :src="
                                                'https://flagcdn.com/h20/' +
                                                name +
                                                '.png'
                                              "
                                              height="6"
                                            />
                                            <div
                                              v-if="value.affiliation"
                                              class="d-inline p-1 pr-2"
                                            >
                                              <i class="fas fa-link"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Draggable>
                            </Container>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="container-fluid m-0 p-0">
                            <div class="row">
                              <div class="col">
                                <div
                                  :class="
                                    pourcProteine >= 22 && pourcProteine <= 28
                                      ? 'alert-success'
                                      : 'alert-danger'
                                  "
                                  class="alert alert-success text-center"
                                  role="alert"
                                >
                                  <p class="fs-1 mb-0 fw-bolder">
                                    {{ pourcProteine }}g
                                  </p>
                                  <p>protéine</p>
                                  <p>22% - 28%</p>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  :class="
                                    pourcGlucide >= 35 && pourcGlucide <= 45
                                      ? 'alert-success'
                                      : 'alert-danger'
                                  "
                                  class="alert alert-success text-center"
                                  role="alert"
                                >
                                  <p class="fs-1 mb-0 fw-bolder">
                                    {{ pourcGlucide }}g
                                  </p>
                                  <p>glucide</p>
                                  <p>35% - 45%</p>
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  :class="
                                    pourcLipide >= 32 && pourcLipide <= 38
                                      ? 'alert-success'
                                      : 'alert-danger'
                                  "
                                  class="alert alert-success text-center"
                                  role="alert"
                                >
                                  <p class="fs-1 mb-0 fw-bolder">
                                    {{ pourcLipide }}g
                                  </p>
                                  <p>lipide</p>
                                  <p>32% - 38%</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h5>Ingredients:</h5>
                          <hr />
                          <Container
                            group-name="1"
                            @drop="onDrop($event)"
                            :key="'ingredientsList' + recette.id"
                            style="min-height: 100px;"
                          >
                            <Draggable
                              v-for="(ingredient, key) in recette.data
                                .ingredients"
                              :key="'ingredientsrecette' + key"
                            >
                              <div class="draggable-item">
                                <div
                                  class="boxIngredient container"
                                  :id="'boxIngredientRecette' + key"
                                >
                                  <div class="row">
                                    <div class="col">
                                      <!--{{ showIngredientFromRef(ingredient.ingredient) }}-->
                                      <async-component
                                        :key="
                                          'ingredient-' +
                                          recette.id +
                                          key +
                                          forceUpdateList
                                        "
                                        :updateMacrosCalcul="updateMacrosCalcul"
                                        :ingredientref="ingredient.ingredient"
                                      ></async-component>
                                    </div>
                                    <div
                                      :data-quantite="ingredient.quantite"
                                      class="col"
                                    >
                                      <input
                                        type="text"
                                        @keyup="updateMacrosCalcul()"
                                        class="form-control"
                                        v-model.number="ingredient.quantite"
                                        :id="
                                          'quantiteingredient' +
                                          recette.id +
                                          ingredient.ingredient
                                        "
                                      />
                                    </div>
                                    <div class="col">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        @change="updateMacrosCalcul()"
                                        v-model="ingredient.hors_calcul"
                                        :id="
                                          'horscalculingredient' +
                                          recette.id +
                                          ingredient.ingredient
                                        "
                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        Hors calcul ?
                                      </label>
                                    </div>
                                    <div class="col-2">
                                      <button
                                        type="button"
                                        @click="deleteIngredient(key)"
                                        class="btn btn-danger"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Draggable>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
